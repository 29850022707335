const performanceList = [
  {
    title: 'videoStreamAnalytics.performanceList.item1.title',
    value: '99',
    description: 'videoStreamAnalytics.performanceList.item1.description',
    link: 'https://static.mlytics.com/downloads/How_Mlytics_Video_Stream_helps_live_streaming_companies_succeed_with_sport,_music_festivals,_or_big_events_streaming_in_multiple_locations_across_Asia.pdf'
  },
  {
    title: 'videoStreamAnalytics.performanceList.item2.title',
    value: '35',
    description: 'videoStreamAnalytics.performanceList.item2.description',
    link: 'https://static.mlytics.com/downloads/How_Mlytics_Video_Stream_helps_live_streaming_companies_succeed_with_sport,_music_festivals,_or_big_events_streaming_in_multiple_locations_across_Asia.pdf'
  },
  {
    title: 'videoStreamAnalytics.performanceList.item3.title',
    value: '97',
    description: 'videoStreamAnalytics.performanceList.item3.description',
    link: 'https://static.mlytics.com/downloads/How_Mlytics_Video_Stream_helps_live_streaming_companies_succeed_with_sport,_music_festivals,_or_big_events_streaming_in_multiple_locations_across_Asia.pdf'
  },
  {
    title: 'videoStreamAnalytics.performanceList.item4.title',
    value: '90',
    description: 'videoStreamAnalytics.performanceList.item4.description',
    link: 'https://static.mlytics.com/downloads/How_Mlytics_Video_Stream_help_sport_association_expand_to_the_OTT_platform.pdf'
  }
]

export default performanceList
