const partnerList = {
  tech: [
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_aws.png',
      alt: 'aws'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_gcp.png',
      alt: 'gcp'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_microsoft.png',
      alt: 'microsoft'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_tencent.png',
      alt: 'tencent'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_alibaba.png',
      alt: 'alibaba'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_mux.png',
      alt: 'mux'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_kkcompany.png',
      alt: 'kkcompany'
    }
  ],
  service: [
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_searce.png',
      alt: 'searce'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_ecloudValley.png',
      alt: 'ecloudValley'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_eCloudrover.png',
      alt: 'eCloudrover'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_masterConcept.png',
      alt: 'masterConcept'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_vnis.png',
      alt: 'vnis'
    },
    {
      src: 'https://static.mlytics.com/images/website/logo_partner_CloudRiches.png',
      alt: 'CloudRiches'
    }
  ]
}
export default partnerList
