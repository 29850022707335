import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import GotoDemoHero from 'components/hero/gotoDemo'
import FadeCarousel from 'components/carousel/fade'
import NewsCard from 'components/card/news'
import FlexPosterCard from 'components/card/flexPoster'
import SolidDropdown from 'components/dropdown/solid'
import I18nImage from 'components/gallery/i18nImage'
import { setImagePostfix } from 'utils/i18n'

import { redirectTo } from 'utils'

import {
  interestedProductList,
  productCategoryFeatureList,
  productAspectList,
  surveyFeaturesList
} from '/static/data/home.static'
import partnerSlides from '/static/data/partnerSlides.static'
import performanceList from '/static/data/performanceList.static'
import newsList from '/static/data/newsList.static'
import companyList from '/static/data/company.static'
import partnerList from '/static/data/partner.static'
import { Card, CardContent, Tabs, Tab } from '@mui/material'

const IndexPage = () => {
  const { t } = useTranslation()
  const { screenXL } = useBreakpoint()

  const [interestedProduct, setInterestedProduct] = useState('web')
  const [productStructureClickIndex, setProductStructureClickIndex] = useState(0)
  const [navDistance, setNavDistance] = useState(100)

  const backgroundImageSrc =
    interestedProduct === 'stream'
      ? interestedProductList[0].backgroundImageSrc
      : interestedProductList[1].backgroundImageSrc

  const PRODUCT_ASPECT_SELECT = Object.keys(productAspectList).reduce((result, key) => {
    const items = productAspectList[key].map((item, index) => ({
      name: t(item.title),
      value: index
    }))
    result[key] = items
    return result
  }, {})

  const clickProductStructureButton = (index) => {
    setProductStructureClickIndex(index)
  }

  const buttonClassName = 'mb-3 mx-auto btn border-2 border-blue-300'

  const handleScroll = () => {
    const navElement = document.getElementById('sticky_nav')
    if (navElement) {
      const rect = navElement.getBoundingClientRect()
      const distance = rect.top
      setNavDistance(distance)
    }
  }

  useEffect(() => {
    setProductStructureClickIndex(0)
  }, [interestedProduct])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [navDistance])
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'All-in-One CDN Solutions for Streaming & China Access',
            metaDescription:
              "Mlytics's multi-CDN solutions help businesses to offer a seamless video streaming experience, access to the China market, DDoS, Origin Shield, and more.",
            metaUrl: 'https://www.mlytics.com/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />

        <section style={{ height: 80 }} className="relative z-10 flex justify-center py-2 w-full">
          <Tabs
            value={interestedProduct}
            onChange={(event, value) => setInterestedProduct(value)}
            aria-label="icon position tabs interested-product"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#FFFFFF',
                height: 4
              }
            }}
          >
            {interestedProductList.map((product, productIndex) => (
              <Tab
                key={productIndex}
                icon={
                  // Todo: find another way to change svg icon color
                  <img
                    src={product.iconImageSrc}
                    style={{
                      filter:
                        interestedProduct === product.value
                          ? 'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)'
                          : 'invert(29%) sepia(0%) saturate(15%) hue-rotate(227deg) brightness(105%) contrast(81%)'
                    }}
                  />
                }
                iconPosition="start"
                label={t(product.name)}
                value={product.value}
                sx={{
                  '&.MuiTab-root': {
                    fontWeight: 700,
                    color: '#575757',
                    fontSize: '16px'
                  },
                  '&.Mui-selected': {
                    color: '#FFFFFF'
                  }
                }}
              />
            ))}
          </Tabs>
        </section>
        <section
          style={{
            marginTop: -80,
            minHeight: 'calc(100vh - 80px)',
            maxHeight: '100%',
            backgroundImage: `url(${setImagePostfix(backgroundImageSrc)}), url(${backgroundImageSrc})`,
            backgroundRepeat: 'none',
            backgroundSize: 'cover',
            backgroundPosition: `${screenXL ? 'center' : interestedProduct === 'stream' ? 'right' : 'left'} center`
          }}
          className="p-5 flex flex-col justify-center md:justify-around bg-black text-white transition-background-image duration-500 ease-in-out"
        >
          <div
            className="container"
            style={{
              marginTop: 80
            }}
          >
            <h1 className="my-5 md:mb-16 text-center leading-tight text-3xl md:text-4.5xl">
              {t(`index.section1.${interestedProduct}.title.0`)}
              <div>
                <span>{t(`index.section1.${interestedProduct}.title.1`)}</span>{' '}
                <span className="text-secondary-600 underline underline-offset-4">
                  {t(`index.section1.${interestedProduct}.lastWord`)}
                </span>
              </div>
            </h1>
          </div>
          <div className="mb-8 p-5 md:p-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[40px] text-2xl">
            <div
              className={`md:px-10 text-xl md:text-2xl ${
                interestedProduct === 'stream' ? 'lg:col-start-2 xl:col-start-3' : 'col-start-1 col-end-1'
              }`}
            >
              <Trans i18nKey={`index.section1.${interestedProduct}.description`}>
                <a className="font-bold text-3xl"></a>
              </Trans>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 my-1 justify-center items-center">
            <Link
              id={`lp_${interestedProduct}_banner_01_try`}
              to="/signup/"
              className="btn btn-bold btn-orange inline-block"
              style={{ minWidth: 208 }}
            >
              {t('button.freeTrial.0')}
            </Link>
            <Link
              id={`lp_${interestedProduct}_banner_02_demo`}
              to="/lp/get-a-demo/"
              className="btn border-2 btn-orange btn-ghost btn-bold  inline-block"
              style={{ minWidth: 208 }}
            >
              {t('button.getDemo.1')}
            </Link>
          </div>
        </section>
        <section>
          <nav
            style={{ height: 80 }}
            id="sticky_nav"
            className={`sticky top-[60px] z-10 flex justify-center mb-3 pt-2 pb-3 w-full bg-white transition-opacity duration-300 ease-in-out ${
              navDistance <= 84 ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <Tabs
              value={interestedProduct}
              onChange={(event, value) => setInterestedProduct(value)}
              aria-label="icon position tabs interested-product"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#21838D',
                  height: 4
                }
              }}
            >
              {interestedProductList.map((product, productIndex) => (
                <Tab
                  key={productIndex}
                  icon={
                    <img
                      src={product.iconImageSrc}
                      style={{
                        filter:
                          interestedProduct === product.value
                            ? 'invert(43%) sepia(59%) saturate(489%) hue-rotate(137deg) brightness(89%) contrast(95%)'
                            : 'invert(29%) sepia(0%) saturate(15%) hue-rotate(227deg) brightness(105%) contrast(81%)'
                      }}
                    />
                  }
                  iconPosition="start"
                  label={t(product.name)}
                  value={product.value}
                  sx={{
                    '&.MuiTab-root': {
                      fontWeight: 700,
                      color: '#575757',
                      fontSize: '16px'
                    },
                    '&.Mui-selected': {
                      color: '#21838D'
                    }
                  }}
                />
              ))}
            </Tabs>
          </nav>
          <section
            style={{
              marginTop: -80
            }}
          >
            <div className="md:container bg-white-gradient py-12 px-4 md:px-10">
              <h2 className="text-center text-primary-600 my-10 text-3xl md:text-4.5xl">{t('index.section2.title')}</h2>
              <div className="gap-5 grid grid-cols-1 md:grid-cols-2">
                {productCategoryFeatureList[interestedProduct].map((item, index) => (
                  <FlexPosterCard
                    key={index}
                    index={index}
                    iconImageSrc={item.iconImageSrc}
                    iconImageAlt={item.iconImageAlt}
                    defaultImageSrc={item.defaultImageSrc}
                    defaultImageAlt={item.defaultImageAlt}
                    title={item.name}
                    description={item.description}
                    buttons={item.items}
                  />
                ))}
              </div>
            </div>
          </section>
          <section className="container py-12 text-center">
            <h2 className="mb-5 text-primary-600 text-3xl md:text-4.5xl">{t('index.section3.title')}</h2>
            <p className="text-gray-600">{t('index.section3.subtitle')}</p>
            <div className="flex flex-col lg:flex-row gap-3 items-center justify-center pt-6">
              <Link
                id={`lp_${interestedProduct}_feature_01_try`}
                to="/signup/"
                className="btn btn-bold btn-orange inline-block"
                style={{ minWidth: 208 }}
              >
                {t('button.freeTrial.1')}
              </Link>
              <Link
                id={`lp_${interestedProduct}_feature_02_demo`}
                to="/lp/get-a-demo/"
                className="btn btn-bold btn-ghost btn-orange inline-block"
                style={{ minWidth: 208 }}
              >
                {t('button.getDemo.2')}
              </Link>
            </div>
          </section>
          <section className="py-16 text-center bg-blue-200 bg-opacity-5">
            <div className="container text-center">
              <h2 className="mb-6 text-primary-500 text-3xl md:text-4.5xl">{t('index.section4.title')}</h2>
              <p className="mb-16 text-gray-600">{t('index.section4.subtitle')}</p>
              <div className="container grid grid-cols-1 lg:grid-cols-5 gap-5">
                <div className="my-5 flex-col col-span-2 hidden md:flex">
                  {productAspectList[interestedProduct].map((item, index) => (
                    <button
                      id={item.clickID}
                      key={index}
                      className={
                        index === productStructureClickIndex
                          ? `${buttonClassName} bg-blue-300 text-white`
                          : `${buttonClassName} text-blue-300`
                      }
                      onClick={() => clickProductStructureButton(index)}
                      style={{ height: 'auto', width: 320 }}
                    >
                      {t(item.title)}
                    </button>
                  ))}
                </div>
                <div className="mb-5 flex md:hidden col-span-3">
                  <SolidDropdown
                    currentOption={productStructureClickIndex}
                    options={PRODUCT_ASPECT_SELECT[interestedProduct]}
                    changeMenuState={(event, index) => clickProductStructureButton(index)}
                  />
                </div>

                <div className="col-span-3">
                  <Card
                    className="rounded-lg"
                    sx={{
                      '&.MuiPaper-root': {
                        boxShadow: 'none',
                        overflow: 'visible',
                        borderRadius: '32px'
                      }
                    }}
                  >
                    <div className="relative md:h-[20rem] h-[11rem] w-[105%]">
                      {productAspectList[interestedProduct].map((item, index) => (
                        <I18nImage
                          key={index}
                          className={`absolute bottom-0 left-0 transition-opacity duration-1000 ease-in-out md:h-[22rem] h-[13rem] w-[105%] ${
                            productStructureClickIndex === index
                              ? 'opacity-100 scale-100 h-auto'
                              : 'opacity-0 scale-0 h-0'
                          }`}
                          src={item.imageSrc}
                          alt={t(item.imageAlt)}
                          style={{
                            width: '110%',
                            height: 'auto'
                          }}
                        />
                      ))}
                    </div>

                    <CardContent className="rounded-b-[32px] bg-blue-50 bg-opacity-30">
                      {productAspectList[interestedProduct].map((item, index) => (
                        <p
                          key={index}
                          className={`text-gray-700 transition-opacity duration-1000 ease-in-out ${
                            productStructureClickIndex === index
                              ? 'opacity-100 scale-100 h-auto'
                              : 'opacity-0 scale-0 h-0'
                          }`}
                        >
                          {t(item.description)}
                        </p>
                      ))}
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="py-16 text-center">
          <h2 className="mb-10 text-primary-500 text-3xl md:text-4.5xl">{t('index.section6.title')}</h2>
          <div className="container px-5 md:px-0 grid grid-cols-1 lg:grid-cols-3 gap-12">
            {surveyFeaturesList.map((item, index) => (
              <div key={index} className="flex flex-col justify-between">
                <div className="flex-1 flex flex-col ">
                  <h3 className="mb-3 text-gray-800">{t(item.title)}</h3>
                  <img
                    className="mb-3"
                    src={item.imageSrc}
                    alt={t(item.imageAlt)}
                    style={{ height: 'auto', width: '100%' }}
                  />
                </div>
                <div>
                  <p className="mb-3 text-center text-gray-800">
                    <Trans i18nKey={item.description}>
                      {item.descriptionLink?.includes('https://') ? (
                        <a
                          id={item.clickID}
                          href={item.descriptionLink}
                          className="underline"
                          style={{ height: 'auto', minWidth: 320 }}
                        ></a>
                      ) : (
                        <Link
                          id={item.clickID}
                          to={item.descriptionLink}
                          className="underline"
                          style={{ height: 'auto', minWidth: 320 }}
                        ></Link>
                      )}
                    </Trans>
                  </p>
                  {item.isOutboundLink ? (
                    <a
                      id={item.clickID}
                      href={item.href}
                      className="btn btn-blue mx-auto"
                      style={{ height: 'auto', minWidth: 320 }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(item.linkText)}
                    </a>
                  ) : (
                    <Link
                      id={item.clickID}
                      to={item.href}
                      className="btn btn-blue mx-auto"
                      style={{ height: 'auto', minWidth: 320 }}
                    >
                      {t(item.linkText)}
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>

        <GotoDemoHero clickIds={['lp_sup_01_try', 'lp_sup_02_demo']} />

        <section className="bg-gray-100 px-4 md:px-20 py-10 text-center">
          <h2 className="mb-6 text-primary-600 text-4.5xl">{t('index.section8.title')}</h2>
          <p className="text-gray-600">{t('index.section8.subtitle')}</p>
          <div className="flex flex-col md:flex-row gap-10 md:gap-20 justify-center items-center py-7">
            <img
              src="https://static.mlytics.com/images/website/logo_social_proof_iso.png"
              alt="iso"
              height="80"
              width="156"
            />
            <img
              src="https://static.mlytics.com/images/website/logo_social_proof_aws.png"
              alt="aws"
              height="80"
              width="80"
            />
            <img
              src="https://static.mlytics.com/images/website/logo_social_proof_csro.png"
              alt="csro"
              height="80"
              width="176"
            />
          </div>
        </section>
        <section className="container px-4 mb-24 md:px-10 pt-20">
          <h2 className="text-center text-primary-600 text-4.5xl">{t('videoStreamAnalytics.section12.title')}</h2>
          <div className="flex flex-col md:flex-row pt-11">
            <div className="md:border-r border-gray-900 flex-0 shrink-0 p-7 text-center md:text-right font-bold basis-[90px] lg:basis-[220px]">
              {t('videoStreamAnalytics.section12.item1')}
            </div>
            <div className="flex flex-wrap justify-center md:justify-start">
              {partnerList.tech.map((item) => (
                <img key={item.alt} src={item.src} alt={item.alt} height="100" width="200" className="mx-2.5" />
              ))}
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-5">
            <div className="md:border-r border-gray-900 flex-0 shrink-0 p-7 text-center md:text-right font-bold basis-[90px] lg:basis-[220px]">
              {t('videoStreamAnalytics.section12.item2')}
            </div>
            <div className="flex flex-wrap justify-center md:justify-start">
              {partnerList.service.map((item) => (
                <img key={item.alt} src={item.src} alt={item.alt} height="100" width="200" className="mx-2.5" />
              ))}
            </div>
          </div>
        </section>
        <section className="container px-4 md:px-10 mb-24">
          <div className="text-blue-200 text-center text-2xl">{t('videoStreamAnalytics.section11.subtitle')}</div>
          <h2 className="text-center text-4.5xl text-primary-600">{t('videoStreamAnalytics.section11.title')}</h2>
          <div className="flex flex-wrap justify-center pt-11">
            {companyList.map((item) => (
              <img key={item.alt} src={item.src} alt={item.alt} height="100" width="200" className="mx-4" />
            ))}
          </div>
        </section>
        <section className="bg-gray-50">
          <FadeCarousel slides={partnerSlides} />
        </section>
        <section className="bg-primary-500 flex justify-evenly px-10 py-16">
          <div className="grid gap-10 md:grid-cols-2 xl:grid-cols-4 text-center text-white">
            {performanceList.map((item, index) => (
              <div key={index} className="flex flex-col justify-between mx-5 py-4" style={{ width: '230px' }}>
                <div>
                  <h4 className="mb-2 uppercase">{t(item.title)}</h4>
                  <div className="text-center">
                    <span className="font-black text-7xl">{item.value}</span>
                    <span className="font-bold text-5xl">%</span>
                  </div>
                  <div className="mt-6">{t(item.description)}</div>
                </div>
                <button
                  className="btn btn-white leading-6 mt-5 px-5 py-3 rounded text-center tracking-wider text-primary-600"
                  onClick={redirectTo(item.link, '_blank')}
                >
                  {t('videoStreamAnalytics.section9.buttonText')}
                </button>
              </div>
            ))}
          </div>
        </section>
        <section className="px-4 md:px-10 py-20">
          <h2 className="text-center text-primary-600 text-6xl mb-10">{t('heading.latestNews')}</h2>
          <div className="flex flex-col xl:flex-row items-center justify-center py-8">
            {newsList.map((item, index) => (
              <NewsCard
                key={index}
                className="w-full xl:mx-2 mb-3"
                flag={t(item.flag)}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                paragraph={t(item.paragraph)}
                anchorHref={item.anchorHref}
                imageSrc={item.imageSrc}
                imageAlt={t(item.imageAlt)}
              />
            ))}
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
