import { navItems } from '../../src/components/navbar/navbar.static'
const interestedProductList = [
  {
    name: 'navigation.category2.list1.name',
    iconImageSrc: 'https://static.mlytics.com/images/website/icon_stream.svg',
    iconImageAlt: 'navigation.category2.list1.name',
    backgroundImageSrc: '/images/stream_hero_background.png',
    backgroundImageAlt: 'navigation.category2.list1.name',
    value: 'stream'
  },
  {
    name: 'navigation.category2.list2.name',
    iconImageSrc: 'https://static.mlytics.com/images/website/icon_web.svg',
    iconImageAlt: 'navigation.category2.list2.name',
    backgroundImageSrc: 'https://static.mlytics.com/images/website/web_hero_background.webp',
    backgroundImageAlt: 'navigation.category2.list2.name',
    value: 'web'
  }
]

const productCategoryFeatureList = {
  stream: [
    {
      type: 'category',
      name: 'navigation.category2.header.column1',
      description: 'index.productCategoryFeatureList.stream.0.description',
      iconImageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/05/icon-observability.svg',
      iconImageAlt: 'navigation.category2.header.column1',
      items: [
        {
          clickID: 'lp_stream_feature_03_analytics',
          text: 'navigation.category2.list1.list1.name4',
          link: navItems[1].list[0].list[0].list[0].link,
          isOutboundLink: false
        }
      ]
    },
    {
      type: 'category',
      name: 'navigation.category2.header.column2',
      description: 'index.productCategoryFeatureList.stream.1.description',
      iconImageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/05/icon-optimization.svg',
      iconImageAlt: 'navigation.category2.header.column2',
      items: [
        {
          clickID: 'lp_stream_feature_04_switch',
          text: 'navigation.category2.list1.list1.name2',
          link: '/video-stream/instream-switching/',
          isOutboundLink: false
        }
      ]
    },
    {
      type: 'category',
      name: 'navigation.category2.header.column3',
      description: 'index.productCategoryFeatureList.stream.2.description',
      iconImageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/05/icon-delivery.svg',
      iconImageAlt: 'navigation.category2.header.column3',
      items: [
        {
          clickID: 'lp_stream_feature_05_01_multi',
          text: 'navigation.category2.list1.list1.name1',
          link: '/video-stream/multi-cdn-management/',
          isOutboundLink: false
        },
        {
          clickID: 'lp_stream_feature_05_02_p2p',
          text: 'navigation.category2.list1.list1.name3',
          link: '/video-stream/hybrid-cdn-p2p/',
          isOutboundLink: false
        },
        {
          clickID: 'lp_stream_feature_05_03_ll',
          text: 'navigation.category2.list1.list1.name5',
          link: '/video-stream/low-latency-live-streaming/',
          isOutboundLink: false
        }
        // {
        //   clickID: 'lp_stream_feature_05_04_ll',
        //   text: 'navigation.category1.list1.list1.name1',
        //   link: '/solutions/video-stream-china/',
        //   isOutboundLink: false
        // }
      ]
    },
    {
      type: 'category',
      name: 'navigation.category2.header.column5',
      description: 'index.productCategoryFeatureList.stream.3.description',
      iconImageSrc: '/images/svg/currency_exchange.svg',
      iconImageAlt: 'navigation.category2.header.column5',
      items: [
        {
          clickID: 'lp_stream_feature_06_01_multi',
          text: 'navigation.category2.list1.list1.name6',
          link: '',
          isOutboundLink: false
        }
      ]
    }
  ],
  web: [
    {
      type: 'category',
      name: 'navigation.category2.header.column1',
      description: 'index.productCategoryFeatureList.web.0.description',
      iconImageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/05/icon-observability.svg',
      iconImageAlt: 'navigation.category2.header.column1',
      items: [
        {
          clickID: 'lp_web_feature_03_01_real',
          text: 'navigation.category2.list2.list1.list1.name2',
          link: {
            en: 'https://www.mlytics.com/features/real-user-monitoring/',
            zh: 'https://www.mlytics.com/zh/features/real-user-monitoring/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/real-user-monitoring/'
          },
          isOutboundLink: true
        },
        {
          clickID: 'lp_web_feature_03_02_syn',
          text: 'navigation.category2.list2.list1.list1.name3',
          link: {
            en: 'https://www.mlytics.com/features/synthetic-monitoring/',
            zh: 'https://www.mlytics.com/zh/features/synthetic-monitoring/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/synthetic-monitoring/'
          },
          isOutboundLink: true
        },
        {
          clickID: 'lp_web_feature_03_03_pulse',
          text: 'navigation.category2.list2.list1.list1.name1',
          link: {
            en: 'https://www.mlytics.com/features/pulse/',
            zh: 'https://www.mlytics.com/zh/features/pulse/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/pulse/'
          },
          isOutboundLink: true
        }
      ]
    },
    {
      type: 'category',
      name: 'navigation.category2.header.column2',
      description: 'index.productCategoryFeatureList.web.1.description',
      iconImageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/05/icon-optimization.svg',
      iconImageAlt: 'navigation.category2.header.column2',
      items: [
        {
          clickID: 'lp_web_feature_04_01_slb',
          text: 'navigation.category2.list2.list2.list1.name2',
          link: {
            en: 'https://www.mlytics.com/features/smart-load-balancing/',
            zh: 'https://www.mlytics.com/zh/features/smart-load-balancing/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/smart-load-balancing/'
          },
          isOutboundLink: true
        },
        {
          clickID: 'lp_web_feature_04_02_dns',
          text: 'navigation.category2.list2.list2.list1.name1',
          link: {
            en: 'https://www.mlytics.com/features/dns/',
            zh: 'https://www.mlytics.com/zh/features/dns/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/dns/'
          },
          isOutboundLink: true
        },
        {
          clickID: 'lp_web_feature_04_03_routing',
          text: 'navigation.category2.list2.list2.list1.name3',
          link: {
            en: 'https://www.mlytics.com/features/routing-policy/',
            zh: 'https://www.mlytics.com/zh/features/routing-policy/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/routing-policy/'
          },
          isOutboundLink: true
        }
      ]
    },
    {
      type: 'category',
      name: 'navigation.category2.header.column3',
      description: 'index.productCategoryFeatureList.web.2.description',
      iconImageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/05/icon-delivery.svg',
      iconImageAlt: 'navigation.category2.header.column3',
      items: [
        {
          clickID: 'lp_web_feature_05_01_multi',
          text: 'navigation.category2.list2.list3.list1.name1',
          link: {
            en: 'https://www.mlytics.com/features/multi-cdn/',
            zh: 'https://www.mlytics.com/zh/features/multi-cdn/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/multi-cdn/'
          },
          isOutboundLink: true
        },
        {
          clickID: 'lp_web_feature_05_02_market',
          text: 'navigation.category2.list2.list3.list1.name2',
          link: '/features/power-ups/',
          isOutboundLink: false
        },
        {
          clickID: 'lp_web_feature_05_03_access',
          text: 'navigation.category2.list2.list3.list1.name3',
          link: '/features/china-access/',
          isOutboundLink: false
        }
      ]
    },
    {
      type: 'category',
      name: 'navigation.category2.header.column4',
      description: 'index.productCategoryFeatureList.web.3.description',
      iconImageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/05/icon-protection.svg',
      iconImageAlt: 'navigation.category2.header.column4',
      items: [
        {
          clickID: 'lp_web_feature_06_01_shield',
          text: 'navigation.category2.list2.list4.list1.name2',
          link: '/features/origin-shield/',
          isOutboundLink: false
        },
        {
          clickID: 'lp_web_feature_06_02_waf',
          text: 'navigation.category2.list2.list4.list1.name1',
          link: {
            en: 'https://www.mlytics.com/features/web-application-firewall/',
            zh: 'https://www.mlytics.com/zh/features/web-application-firewall/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/web-application-firewall/'
          },
          isOutboundLink: true
        },
        {
          clickID: 'lp_web_feature_06_03_api',
          text: 'navigation.category2.list2.list4.list1.name3',
          link: {
            en: 'https://www.mlytics.com/features/api-protection/',
            zh: 'https://www.mlytics.com/zh/features/api-protection/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/api-protection/'
          },
          isOutboundLink: true
        },
        {
          clickID: 'lp_web_feature_06_04_bot',
          text: 'navigation.category2.list2.list4.list1.name7',
          link: {
            en: 'https://www.mlytics.com/features/bot-management/',
            zh: 'https://www.mlytics.com/zh/features/bot-management/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/bot-management/'
          },
          isOutboundLink: true
        },
        {
          clickID: 'lp_web_feature_06_05_ddos',
          text: 'navigation.category2.list2.list4.list1.name4',
          link: {
            en: 'https://www.mlytics.com/features/ddos-protection/',
            zh: 'https://www.mlytics.com/zh/features/ddos-protection/',
            'zh-tw': 'https://www.mlytics.com/zh-tw/features/ddos-protection/'
          },
          isOutboundLink: true
        }
      ]
    }
  ]
}
const productAspectList = {
  stream: [
    {
      clickID: 'lp_stream_integration_01_LCD',
      title: 'index.productAspectList.stream.0.title',
      description: 'index.productAspectList.stream.0.description',
      imageSrc: 'https://static.mlytics.com/images/website/stream_lcd.png',
      imageAlt: 'index.productAspectList.stream.0.title'
    },
    {
      clickID: 'lp_stream_integration_02_inte',
      title: 'index.productAspectList.stream.1.title',
      description: 'index.productAspectList.stream.1.description',
      imageSrc: 'https://static.mlytics.com/images/website/stream_integration.png',
      imageAlt: 'index.productAspectList.stream.1.title'
    },
    {
      clickID: 'lp_stream_integration_03_smart',
      title: 'index.productAspectList.stream.2.title',
      description: 'index.productAspectList.stream.2.description',
      imageSrc: 'https://static.mlytics.com/images/website/stream_smart_adapt.png',
      imageAlt: 'index.productAspectList.stream.2.title'
    },
    {
      clickID: 'lp_stream_integration_04_analyics',
      title: 'index.productAspectList.stream.3.title',
      description: 'index.productAspectList.stream.3.description',
      imageSrc: 'https://static.mlytics.com/images/website/stream_analytics.png',
      imageAlt: 'index.productAspectList.stream.3.title'
    }
  ],
  web: [
    {
      clickID: 'lp_web_integration_01_inte',
      title: 'index.productAspectList.web.0.title',
      description: 'index.productAspectList.web.0.description',
      imageSrc: 'https://static.mlytics.com/images/website/web_integration.png',
      imageAlt: 'index.productAspectList.web.0.title'
    },
    {
      clickID: 'lp_web_integration_02_perf',
      title: 'index.productAspectList.web.1.title',
      description: 'index.productAspectList.web.1.description',
      imageSrc: 'https://static.mlytics.com/images/website/web_performance.png',
      imageAlt: 'index.productAspectList.web.1.title'
    },
    {
      clickID: 'lp_web_integration_03_secu',
      title: 'index.productAspectList.web.2.title',
      description: 'index.productAspectList.web.2.description',
      imageSrc: 'https://static.mlytics.com/images/website/web_security.png',
      imageAlt: 'index.productAspectList.web.2.title'
    },
    {
      clickID: 'lp_web_integration_04_analyics',
      title: 'index.productAspectList.web.3.title',
      description: 'index.productAspectList.web.3.description',
      imageSrc: 'https://static.mlytics.com/images/website/web_analytics.png',
      imageAlt: 'index.productAspectList.web.3.title'
    }
  ]
}

const surveyFeaturesList = [
  {
    title: 'index.surveyFeaturesList.0.title',
    description: 'index.surveyFeaturesList.0.description',
    descriptionLink: 'https://www.mlytics.com/blog/ultimate-video-streaming-solution',
    imageSrc: 'https://static.mlytics.com/images/website/monitoring_hubs.png',
    imageAlt: 'index.surveyFeaturesList.0.title',
    href: 'https://hubble.mlytics.com/basic_test',
    linkText: 'index.surveyFeaturesList.0.linkText',
    clickID: 'lp_sup_03_test',
    isOutboundLink: true
  },
  {
    title: 'index.surveyFeaturesList.1.title',
    description: 'index.surveyFeaturesList.1.description',
    descriptionLink: '/features/china-access/',
    imageSrc: 'https://static.mlytics.com/images/website/cost_effective_cdn_marketplace.png',
    imageAlt: 'index.surveyFeaturesList.1.title',
    href: '/features/power-ups/',
    linkText: 'index.surveyFeaturesList.1.linkText',
    clickID: 'lp_sup_04_shop',
    isOutboundLink: false
  },
  {
    title: 'index.surveyFeaturesList.2.title',
    description: 'index.surveyFeaturesList.2.description',
    imageSrc: 'https://static.mlytics.com/images/website/soc_support.png',
    imageAlt: 'index.surveyFeaturesList.2.title',
    href: '/features/enterprise-support/',
    linkText: 'index.surveyFeaturesList.2.linkText',
    clickID: 'lp_sup_05_247',
    isOutboundLink: false
  }
]

export { interestedProductList, productCategoryFeatureList, productAspectList, surveyFeaturesList }
