import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  fade: true,
  infinite: true,
  autoplay: true,
  autoSpeed: 6000,
  slidesToShow: 1
}
const Fade = ({ slides }) => {
  const { t } = useTranslation()

  return (
    <div className="m-auto w-4/5 md:w-3/4" style={{ maxWidth: '800px' }}>
      <Slider {...settings} className="py-20">
        {slides.map((slide, index) => (
          <div className="text-center" key={index}>
            <div className="font-bold text-3xl text-primary-500">{t(slide.content)}</div>
            <div className="mt-2">{t(slide.name)}</div>
            <img
              src={slide.logoImg}
              alt={`mlytics customer ${slide.logoName}`}
              className="mt-7 mx-auto"
              height="50"
              width="180"
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

Fade.propTypes = {
  slides: PropTypes.array
}

export default Fade
