const partnerSlides = [
  {
    name: 'videoStreamAnalytics.partnerSlides.item1.name',
    content: 'videoStreamAnalytics.partnerSlides.item1.content',
    logoImg: 'https://static.mlytics.com/images/website/logo_customer_adlink.png',
    logoName: 'ADLINK'
  },
  {
    name: 'videoStreamAnalytics.partnerSlides.item2.name',
    content: 'videoStreamAnalytics.partnerSlides.item2.content',
    logoImg: 'https://static.mlytics.com/images/website/logo_customer_gis.png',
    logoName: 'gis'
  },
  {
    name: 'videoStreamAnalytics.partnerSlides.item3.name',
    content: 'videoStreamAnalytics.partnerSlides.item3.content',
    logoImg: 'https://static.mlytics.com/images/website/logo_customer_trinax.png',
    logoName: 'Trinax'
  },
  {
    name: 'videoStreamAnalytics.partnerSlides.item4.name',
    content: 'videoStreamAnalytics.partnerSlides.item4.content',
    logoImg: 'https://static.mlytics.com/images/website/logo_customer_edifian.png',
    logoName: 'edifian'
  },
  {
    name: 'videoStreamAnalytics.partnerSlides.item5.name',
    content: 'videoStreamAnalytics.partnerSlides.item5.content',
    logoImg: 'https://static.mlytics.com/images/website/logo_customer_yile.png',
    logoName: 'YiLE'
  },
  {
    name: 'videoStreamAnalytics.partnerSlides.item6.name',
    content: 'videoStreamAnalytics.partnerSlides.item6.content',
    logoImg: 'https://static.mlytics.com/images/website/logo_customer_trinax.png',
    logoName: 'Trinax'
  }
]

export default partnerSlides
