import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'

const FlexPoster = ({
  index,
  iconImageSrc,
  iconImageAlt,
  defaultImageSrc,
  defaultImageAlt,
  title,
  description,
  buttons
}) => {
  const { t } = useTranslation()
  const { language } = useI18next()

  return (
    <div
      className={`border-2 border-gray-300 flex flex-col gap-4 p-6 rounded-xl ${
        index % 2 > 0 ? 'lg:flex-row-reverse' : 'lg:flex-row'
      }`}
    >
      <div className="flex-1 text-center">
        <img
          src={iconImageSrc}
          alt={t(iconImageAlt)}
          height="50"
          width="50"
          className="m-auto"
          style={{
            filter: 'invert(30%) sepia(42%) saturate(503%) hue-rotate(137deg) brightness(94%) contrast(90%)'
          }}
        />
        <label className="block capitalize font-bold py-3 text-xl text-blue-300">{t(title)}</label>
        <p className="text-gray-700">{t(description)}</p>
      </div>
      <div className="flex-1 flex flex-col">
        {buttons.length ? (
          buttons.map((button, buttonIndex) =>
            button.isOutboundLink ? (
              <a
                key={buttonIndex}
                id={button.clickID}
                href={button.link[language]}
                className="mx-auto mb-2 btn bg-blue-50 bg-opacity-20 w-full lg:w-[260px] text-gray-700 hover:text-white hover:bg-blue-300 hover:bg-opacity-100"
                style={{ height: 'auto' }}
                target="_blank"
                rel="noreferrer"
              >
                {t(button.text)}
              </a>
            ) : (
              <Link
                key={buttonIndex}
                id={button.clickID}
                to={button.link}
                className={`${
                  button.link ? 'text-gray-700 ' : 'pointer-events-none text-gray-200 '
                }mx-auto mb-2 btn bg-blue-50 bg-opacity-20 w-full lg:w-[260px] hover:text-white hover:bg-blue-300 hover:bg-opacity-100`}
              >
                {t(button.text)}
              </Link>
            )
          )
        ) : (
          <img src={defaultImageSrc} title={t(defaultImageAlt)} style={{ height: 'auto' }} />
        )}
      </div>
    </div>
  )
}

FlexPoster.propTypes = {
  index: PropTypes.number,
  iconImageSrc: PropTypes.string,
  iconImageAlt: PropTypes.string,
  defaultImageSrc: PropTypes.string,
  defaultImageAlt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.array
}

export default FlexPoster
